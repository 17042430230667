<template>
	<v-dialog
		v-model="localDialog"
		:transition="belowDesktop ? 'dialog-bottom-transition' : 'dialog-left-right-transition'"
		:width="belowDesktop ? '100%' : '40%'"
		:fullscreen="belowDesktop"
		@update:dialog="updateDialog"
		content-class="prompt-assistant-dialog"
	>
		<template v-slot:activator="{ props: activatorProps }">
			<slot name="activator" v-bind="activatorProps"></slot>
		</template>

		<v-card v-if="!showLesson" class="d-flex flex-column h-auto rounded-l-0" flat>
			<div
				v-if="!belowDesktop"
				class="d-flex pa-3"
				:class="[localShowStepActionButtons ? 'justify-end' : 'justify-space-between']"
			>
				<button v-if="!localShowStepActionButtons" @click="handleBackAssistantButtonClick" class="font-weight-bold">
					<v-icon class="mr-1">mdi-arrow-left</v-icon>
					Retour à l'assistant
				</button>
				<v-btn icon @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
			<v-card-actions v-else class="d-flex justify-center">
				<v-icon @click="closeDialog" size="25px">{{ !localDialog ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
			</v-card-actions>

			<!-- Content Block -->
			<div class="assistant-block px-4 py-6 d-flex">
				<img class="assistant-img" src="@/assets/icons/assistant.svg" />
				<h3 class="text-center px-2">{{ dialogTitle }}</h3>
				<div class="d-flex w-100">
					<div class="d-flex flex-column w-inherit" v-if="!localShowStepActionButtons">
						<div class="response-container">
							<img class="assistant-avatar" src="@/assets/icons/assistant.svg" v-if="!error" />
							<div class="d-flex justify-center">
								<div class="bg-tag-green-secondary rounded pa-4 answer-content" v-if="!error">
									<div class="d-flex flex-column">
										<div class="loading-wrapper" v-if="loading">
											<div class="loading-dot-typing"></div>
										</div>
										<div v-show="!loading" id="typing-prompt-assistant"></div>
									</div>
								</div>
								<div v-else class="error-container d-flex flex-column justify-center">
									<img class="w-100" src="@/assets/images/error1.png" />
									<p class="text-center">
										Vous êtes nombreux à nous solliciter, nous mettons tout en oeuvre pour répondre à vos questions le
										plus vite !
									</p>
									<button @click="closeDialog" class="ortho-white-button font-weight-bold mt-4">
										Fermer l'assistant
									</button>
								</div>
							</div>
						</div>

						<p v-if="loading && !error" class="ortho-description pt-3 ma-0 pl-14">Est en train d'écrire...</p>
					</div>
				</div>

				<!-- Action Button Block -->
				<div class="d-flex justify-center flex-column" v-if="!localShowStepActionButtons">
					<button
						v-if="!isTypingEffect"
						class="ortho-white-button font-weight-bold mt-4"
						@click="handleShowLessonActionClick"
					>
						Revoir la leçon
					</button>
					<button v-if="!isTypingEffect" @click="closeDialog" class="ortho-white-button font-weight-bold mt-4">
						Fermer l'assistant
					</button>
				</div>
				<div class="d-flex flex-column" v-else>
					<button class="ortho-white-button font-weight-bold" @click="handleIDontUnderstandActionClick">
						Je ne comprends pas
					</button>
					<button class="ortho-white-button font-weight-bold mt-4" @click="handleShowLessonActionClick">
						Revoir la leçon
					</button>
				</div>
			</div>
		</v-card>

		<v-card v-else class="d-flex flex-column h-auto rounded-l-0" flat>
			<div class="pa-4 d-flex flex-column" :class="{ 'align-start': !belowDesktop }">
				<div class="d-flex align-center justify-space-between w-100">
					<button @click="handleBackAssistantButtonClick" class="font-weight-bold">
						<v-icon class="mr-1">mdi-arrow-left</v-icon>
						Retour à l'assistant
					</button>
					<v-btn icon @click="closeDialog">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<RedactionLesson
					v-if="session"
					:isModal="true"
					:session="session"
					:isBlockWithActionDisplay="false"
					:formationProgress="formationProgress"
				/>
				<div class="d-flex justify-center w-100 my-4">
					<button @click="closeDialog" class="ortho-white-button font-weight-bold mt-4">Fermer l'assistant</button>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Axios from 'axios';
import RedactionLesson from '@/components/redaction/RedactionLesson.vue';
import { renderTextTypingEffect, renderTextWithoutTypingEffect } from '@/utils/typing-effect';
import { parseTemplate } from '@/utils/text.js';
import { trackPromptForIDontUnderStandBtn } from '@/constants/sessionPrompt.js';
import { PROMPT_ASSISTANT_TYPE, PROMPT_EXPLANATION_TYPE } from '../../../constants/redaction';

export default {
	name: 'PromptAssistantDialog',
	components: { RedactionLesson },
	props: {
		formationProgress: {
			type: Object,
			required: true,
		},
		promptType: {
			type: String,
			required: true,
			validator(value) {
				return [PROMPT_EXPLANATION_TYPE, PROMPT_ASSISTANT_TYPE].includes(value);
			},
		},
		track: {
			type: Object,
			required: true,
		},
		session: {
			type: Object,
			required: true,
		},
		dialog: {
			type: Boolean,
			required: true,
		},
		previousExplanation: String,
	},
	data() {
		return {
			showLesson: false,
			isTypingEffect: false,
			localDialog: this.dialog,
			localShowStepActionButtons: this.promptType !== PROMPT_EXPLANATION_TYPE,
			loading: false,
			startY: 0,
			intervalID: null,
			hasScrollUp: false,
			error: false,
			axiosSource: Axios.CancelToken.source(),
			memoAnswer: null,
		};
	},
	computed: {
		dialogElement() {
			return Array.from(document.querySelectorAll('.v-dialog')).filter((el) => {
				return window.getComputedStyle(el).display !== 'none';
			})[0];
		},
		belowDesktop() {
			return window.innerWidth <= 648;
		},
		profileUser() {
			const { user } = this.formationProgress;
			return {
				firstName: user.first_name,
				lastName: user.last_name,
				gender: user.gender === 'M' ? 'masculin' : 'feminin',
			};
		},
		dialogTitle() {
			return this.localShowStepActionButtons
				? `Bonjour ${this.profileUser.firstName}, comment puis-je t’aider ?`
				: 'La réponse de l’assistant :';
		},
	},
	watch: {
		dialog(isOpen) {
			this.localDialog = isOpen;
		},
		localDialog(isOpen) {
			this.$emit('update:dialog', isOpen);
			this.showLesson = false;

			if (isOpen && !this.localShowStepActionButtons && !this.showLesson) {
				this.renderAnswerFromOpenAI();
			} else {
				// Cleanup generation content
				const typingElement = document.getElementById('typing-prompt-assistant');
				if (typingElement) {
					typingElement.innerHTML = ''; // Clear all content
				}
			}
		},
		showStepActionButton(newVal) {
			this.localShowStepActionButtons = newVal;
		},
	},
	methods: {
		...mapActions('exercice', ['getQuestionExplanationFromAI']),
		closeDialog() {
			this.localDialog = false;
			this.localShowStepActionButtons = this.promptType !== 'explanation';

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('track-session-closeExplanationSupport');
			}
		},
		updateDialog(value) {
			this.localDialog = value;
		},
		async renderAnswerFromOpenAI() {
			try {
				this.loading = true;
				this.isTypingEffect = true;

				const question = this.createQuestionForAI();
				const answer = await this.getQuestionExplanationFromAI({
					question,
					config: { cancelToken: this.axiosSource.token },
				});

				this.memoAnswer = answer;

				this.dialogElement.addEventListener('scroll', this.handleScroll);

				this.hasScrollUp = false;
				this.intervalID = renderTextTypingEffect(
					answer,
					'typing-prompt-assistant',
					this.scrollToBottomDialogBody,
					this.typingPromptAssistantCallback,
					() => !this.localDialog || this.localShowStepActionButtons
				);
				this.loading = false;
			} catch (e) {
				this.loading = false;
				this.error = true;
			}
		},
		scrollToBottomDialogBody() {
			this.dialogElement.scrollTop = this.dialogElement.scrollHeight;
		},
		typingPromptAssistantCallback() {
			this.isTypingEffect = false;
		},
		createQuestionForAI() {
			const {
				correction_explain_block,
				roll,
				question_type,
				tips,
				description,
				instruction,
				track_extension,
			} = this.track;

			let prompt = '';
			let correctorName =
				correction_explain_block && correction_explain_block.full_name ? correction_explain_block.full_name : 'Maria';

			// Prompt for explanation type (Maria explanation)
			if (this.promptType === PROMPT_EXPLANATION_TYPE) {
				prompt = parseTemplate(trackPromptForIDontUnderStandBtn, {
					firstName: this.profileUser.firstName,
					gender: this.profileUser.gender,
					roll: roll,
					explanation:
						correction_explain_block.use_prompt === true && this.previousExplanation
							? this.previousExplanation
							: correction_explain_block.explanation,
				});
			} else {
				if (question_type === 'QCM') {
					prompt = `
					Tu es professeur de français et super pédagogue.
					Ton apprenant s'appelle "${this.profileUser.firstName}" il est de sexe ${this.profileUser.gender}.
					L'apprenant fait un exercice de type: "${question_type}".
					La consigne de l'exercice est : "${instruction}"
					La description de l'exercice est : "${description}"
					Les astuces de l'exercice sont : "${tips}"
					La structuration se fera exactement comme ceci :

					"Dans un premier temps dit "salut" avec "👋🏼" à l'apprenant en lui disant " j'espère que tu vas bien" avec un émoji.
					Dans un second temps, rassure l'apprenant en utilisant un émoji encourageant, et dis-lui que ce n'est qu'un entrainement et qu'on est là pour apprendre avec un smiley biceps.

					📝Explication de l'exercice : (en utilisant la consigne et la description, explique le plus clairement possible l'exercice en utilisant des mots simples, soit également concis)
					🎯Objectif : (donne l'objectif de l'exercice à l'apprenant).
					💡D'autres Astuces : (À l'aide des astuces déjà proposé à l'apprenant, propose en trois autres).

					Un message d'encouragement/compliment final avec le prénom de l'apprenant.

					Met en gras les parties importantes, utilise des émojis à chaque ligne et, structure bien ta réponse en ajoutant des retours à la ligne et reste concis. Signe le message par "${correctorName}" d'OrthographIQ" avec un émoji poulpe."
					`;
				} else {
					prompt = `
					Tu es professeur de français et super pédagogue.
					Ton apprenant s'appelle "${this.profileUser.firstName}" il est de sexe ${this.profileUser.gender}.
					L'apprenant fait un exercice de type: "${question_type}".
					La consigne de l'exercice est : "${instruction}"
					La description de l'exercice est : "${description}"
					Les astuces de l'exercice sont : "${tips}"
					La structuration se fera exactement comme ceci :

					"Dans un premier temps dit "salut" avec "👋🏼" à l'apprenant en lui disant " j'espère que tu vas bien" avec un émoji.
					Dans un second temps, rassure l'apprenant en utilisant un émoji encourageant, et dis-lui que ce n'est qu'un entrainement et qu'on est là pour apprendre avec un smiley biceps.

					📝Explication de l'exercice : (en utilisant la consigne et la description, explique le plus clairement possible l'exercice en utilisant des mots simples, soit également concis)
					🎯Objectif : (donne l'objectif de l'exercice à l'apprenant).
					💡D'autres Astuces : (À l'aide des astuces déjà proposées à l'apprenant, propose en trois autres).
					🟧Longueur de texte : (il faut expliquer à l'apprenant qu'il doit écrire un texte avec au minimum "${track_extension.min_answer_lenght}" mots)
					🟩Mots-clés : (explique ce que sont les mots clés et leur importance)

					Un message d'encouragement/compliment final avec le prénom de l'apprenant.

					Met en gras les parties importantes, utilise des émojis à chaque ligne et, structure bien ta réponse en ajoutant des retours à la ligne et reste concis. Signe le message par "${correctorName}" d'OrthographIQ" avec un émoji poulpe.`;
				}
			}

			return prompt;
		},
		handleIDontUnderstandActionClick() {
			this.localShowStepActionButtons = false;
			this.renderAnswerFromOpenAI();
		},
		handleShowLessonActionClick() {
			this.showLesson = true;
			this.hasScrollUp = true;

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('track-showLesson');
			}
		},
		handleBackAssistantButtonClick() {
			this.showLesson = false;
			this.localShowStepActionButtons = true;
			this.hasScrollUp = false;
		},
		handleScroll(event) {
			if (this.hasScrollUp) {
				return;
			}

			const scrollY = event.target.scrollTop;

			if (scrollY < this.startY) {
				if (this.memoAnswer) {
					clearInterval(this.intervalID);
					renderTextWithoutTypingEffect(
						this.memoAnswer,
						'typing-prompt-assistant',
						this.typingPromptAssistantCallback,
						() => !this.localDialog || this.localShowStepActionButtons
					);
					this.hasScrollUp = true;
				}
			}
			this.startY = scrollY;
		},
	},

	unmounted() {
		this.dialogElement.removeEventListener('scroll', this.handleScroll);
		this.hasScrollUp = false;
	},
	destroyed() {
		clearInterval(this.intervalID);
		this.axiosSource.cancel();
	},
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
	justify-content: start !important;
}

.assistant-block {
	padding-top: 100px;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 24px;

	.response-container {
		display: flex;
		align-items: flex-start;
		gap: 16px;

		.assistant-avatar {
			width: 40px;
		}

		.error-container {
			width: 70%;
		}
	}

	.assistant-img {
		width: 100px;
	}

	.answer-content {
		width: 100%;
		min-width: 70px;
		p {
			font-size: 16px;
		}

		#typing-prompt-assistant {
			white-space: break-spaces;
		}
	}
}

@media only screen and (max-width: 900px) {
	.track-card .progress-bar-wrapper {
		flex-direction: row;
		align-items: center;

		.progress-values {
			padding-left: 12px;
		}
	}
}

::v-deep {
	.v-dialog {
		margin: 0;
		height: 100%;
		max-height: 100% !important;
		border-radius: 0;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		background-color: white;
	}
}
</style>

<template>
	<div>
		<div class="expression-answer-container d-flex flex-column rounded-lg align-end">
			<div class="form pa-2 w-100 position-relative rounded-lg">
				<v-textarea
					id="expression-textarea"
					class="rounded-lg"
					v-if="!isSubmit"
					v-model="textValue"
					solo
					flat
					rows="10"
					auto-grow
					shaped
					spellcheck="false"
					placeholder="Écris ta réponse ici"
					@copy="disableCopyPaste"
					@paste="disableCopyPaste"
					@cut="disableCopyPaste"
				></v-textarea>
				<div v-else v-html="computedText" class="expression-editable-div rounded-lg px-3 py-2"></div>

				<div class="d-flex justify-end" v-if="!isSubmit && track.track_extension.max_answer_lenght">
					<span
						class="word-count"
						:style="{
							color: this.wordCount < this.track.track_extension.max_answer_lenght ? 'orange' : 'green',
						}"
						>{{ wordCount }} {{ wordCount > 1 ? 'mots' : 'mot' }}</span
					>
				</div>
			</div>
			<div class="d-flex justify-space-between w-100 mt-4" v-if="!isSubmit">
				<BlockAssistant :track="track" :formationProgress="formationProgress" :session="session" v-if="!belowDesktop" />
				<button
					class="submit-button ortho-dark-button font-weight-bold rounded"
					@click="handleAnswerSubmit"
					:disabled="isSubmit || isButtonDisabled"
				>
					Valider
				</button>
			</div>
		</div>
		<v-dialog v-model="isLoading" transition="dialog-fade-transition">
			<v-card class="transparent-card pa-10" flat>
				<v-card-text class="text-center">
					<div class="loading-text mb-6">Ta correction arrive ! 😁</div>
					<v-progress-circular indeterminate color="var(--tag-green-primary)" size="70"></v-progress-circular>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import { PROMPT_ASSISTANT_TYPE } from '../../../constants/redaction';
import BlockAssistant from '../../block/BlockAssistant.vue';
import { getTrackResponseByTrackId } from '../../../utils/redaction';
import { countWord } from '@/utils/text.js';
import { CATEGORIES_DONT_WANT_TO_SHOW } from '@/constants/languageTool.js';

export default {
	name: 'ExpressionAnswerContainer',
	components: { BlockAssistant },
	props: { track: Object, highlightedError: Object, session: Object, formationProgress: Object },
	data() {
		return {
			textValue: '',
			selectedError: null,
			isSubmit: false,
			isLoading: false,
			errors: [],
			promptAssistantType: PROMPT_ASSISTANT_TYPE,
		};
	},
	computed: {
		belowDesktop() {
			return window.innerWidth <= 648;
		},
		wordCount() {
			return countWord(this.textValue);
			// return this.textValue.trim().split(/\s+/).filter(Boolean).length;
		},
		textWithHighlight() {
			let text = this.textValue;
			let offsetAdjustment = 0;

			// Highlight errors form Language Tool response
			if (this.errors) {
				this.errors.forEach((error, index) => {
					const start = error.offset + offsetAdjustment;
					const end = error.offset + error.length;
					const before = text.slice(0, start);
					const after = this.textValue.slice(end);

					// Extract error from textValue
					const errorText = this.textValue.slice(error.offset, end);

					// Format for selected error from Orthographe component
					if (this.selectedError && this.selectedError.indexMatch === index) {
						text = `${before}<span class="highlight-selected-error">${errorText}</span>${after}`;
						offsetAdjustment += '<span class="highlight-selected-error">'.length + '</span>'.length;
					} else {
						text = `${before}<span class="highlight-error">${errorText}</span>${after}`;
						offsetAdjustment += '<span class="highlight-error">'.length + '</span>'.length;
					}
				});
			}

			// Highlight keywords
			const keywords = this.track.track_extension.keywords_expected;
			if (keywords) {
				keywords.forEach((keyword) => {
					const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
					const regex = new RegExp(`(?<!\\w)(${escapedKeyword})(?!\\w)`, 'gi');
					text = text.replace(regex, '<span class="highlight-keyword">$1</span>');
				});
			}

			return text.replace(/\n/g, '<br>');
		},
		computedText() {
			return this.isSubmit ? this.textWithHighlight : this.textValue;
		},
		isButtonDisabled() {
			const minLength = this.track.track_extension.min_answer_lenght;
			return minLength ? this.wordCount < minLength : this.textValue === '';
		},
	},

	watch: {
		highlightedError: {
			handler(error) {
				this.selectedError = error;
			},
		},
	},

	methods: {
		disableCopyPaste(event) {
			event.preventDefault();
			alert(
				"Le copier-coller est désactivé pour ce champ : prenez plaisir à écrire par vous-même. C'est l'occasion idéale de progresser et de vous perfectionner !"
			);
		},
		async submitNewStreakActivity(formationId) {
			await this.$store.dispatch('streak/submitDailyStreakByFormationId', { formationId });
		},
		async fetchLanguageToolCorrection() {
			try {
				delete this.$http.defaults.headers.common['x-auth-token']; // Need this to avoid CORS error

				const response = await axios.post('https://api.languagetoolplus.com/v2/check', null, {
					params: {
						data: { text: this.textValue },
						language: 'fr',
						apiKey: '03351b55217c23e4',
						username: 'jade@orthographiq.com',
						enableHiddenRules: true,
						level: 'picky',
						disabledCategories:
							'CAT_ANGLICISMES_FOREIGN_TERMS,CAT_ARCHAISMES,CAT_CALQUES,MISC,CAT_PLEONASMES,CAT_REGIONALISMES,STYLE,POLITESSE1,ADVANCED_TYPOGRAPHY,CAT_ANGLICISMES',
					},
				});

				const correctionResponse = [];
				response.data.matches.forEach((match) => {
					if (CATEGORIES_DONT_WANT_TO_SHOW.includes(match.rule.category.id)) {
						return;
					}

					correctionResponse.push({
						offset: match.offset,
						length: match.length,
						category: match.rule.category.id,
						message: match.message,
						replacements: match.replacements,
					});
				});

				this.errors = correctionResponse;

				return { correctionResponse };
			} catch (error) {
				return { error: error.message };
			}
		},

		async handleAnswerSubmit() {
			this.isLoading = true;
			let languageToolCorrection = {};
			try {
				const { formationId, sessionId, sessionBlockId } = this.$route.params;
				const trackBodyRequest = {
					formationId,
					sessionId,
					sessionBlockId,
					trackId: this.track._id,
					userAnswers: [this.textValue],
				};

				languageToolCorrection = await this.fetchLanguageToolCorrection();
				if (languageToolCorrection.error == null)
					trackBodyRequest.props = { lang_tool_correction: languageToolCorrection.correctionResponse };

				const [response] = await Promise.all([
					this.$store.dispatch('tracks/createTrackSubmitResponse', trackBodyRequest),
					this.submitNewStreakActivity(formationId),
				]);

				if (response && response.data) {
					const newTrackResponse = response.data.trackResponse;
					await this.$store.dispatch('profile/updateTrackResponse', newTrackResponse);
				}

				this.submitLoading = false;
			} catch (error) {
				this.isLoading = false;
			} finally {
				this.isLoading = false;
				this.isSubmit = true;
				this.$emit('onAnswerSubmit', this.textValue);
				this.$emit('languageToolErrors', this.errors);
				if (languageToolCorrection.error) {
					this.$emit('languageToolAPIFailed', true);
				}
			}
		},
		onInput(event) {
			const text = event.target.innerText;
			this.textValue = text;
		},
	},
	mounted() {
		const { sessionId, sessionBlockId, trackId } = this.$route.params;

		const trackResponse = getTrackResponseByTrackId(this.formationProgress, sessionId, sessionBlockId, trackId);

		if (trackResponse) {
			this.textValue = trackResponse.user_answers[0];
		}
	},
};
</script>

<style lang="scss" scoped>
.expression-answer-container {
	.form {
		width: 100%;
		border: 1px solid #ddd;

		.expression-editable-div {
			width: 100%;
			min-height: 300px;
			outline: none;
			resize: none;
			white-space: pre-wrap;
			text-align: left;

			&:disabled {
				cursor: not-allowed;
			}
		}
		.word-count {
			font-size: 14px;
			background: white;
			padding: 0 10px;
			border-radius: 3px;
		}
	}
	.submit-button {
		width: fit-content;
		&:disabled {
			cursor: not-allowed;
			opacity: 0.7;
		}
	}
}

::v-deep {
	#expression-textarea {
		line-height: 23px;
		font-size: 16px;
	}

	.highlight-error {
		border-bottom: 2px solid var(--tag-violet-primary);
	}

	.highlight-selected-error {
		background-color: #b1a0ef;
		border-bottom: 2px solid var(--tag-violet-primary);
		color: white;
	}
	.highlight-keyword {
		background-color: var(--tag-green-primary);
		color: white;

		border-radius: 2px;
	}
	.v-dialog {
		justify-content: center;
		align-items: center;
		display: flex;
		margin: 0;
		height: 100%;
		max-height: 100% !important;
	}

	.transparent-card {
		display: flex;
		width: 400px;
		justify-content: center;
		align-items: center;
	}

	.loading-text {
		margin-top: 15px;
		font-size: 18px;
		color: #000;
	}
}

@media only screen and (max-width: 900px) {
	.transparent-card {
		width: 350px;
	}

	.expression-answer-container {
		.submit-button {
			width: 100%;
		}
	}
}
</style>
